/*
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
*/

body {
  background-color:#f4f1f2;    /*#f2e6e9; */
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px; 
  margin: 0;
  padding: 0;
  color: #0a0b0b;
}
.heading {
  font-weight: 200;
}
.subheading {
  font-weight: 100;
}

.body {
  background-color:#F5D6C6; /* Creme de pesche */
}

h1 { 
  font-size: 22px; 
  font-weight: 600;
  line-height: 26.2px; 
} 

h2 { 
  font-size: 20px; 
  font-weight: 500;
  line-height: 21.0px; 
} 

h3 { 
  font-size: 18px; 
  font-weight: 500;
  line-height: 15.4px;
} 

h4 { 
  font-size: 16px; 
  font-weight: 500;
  line-height: 15.4px;
} 

img {
  max-width:100%;
  max-height:100%;
  object-fit:contain;
}
.button {
  border:1px solid rgba(129, 24, 92, 1.0);
  border-radius:4px;
  background-color:transparent;
  color: rgba(129, 24, 92, 1.0);
  padding:3px 6px;
  text-align: center;
  font-size: 18px;
  font-weight: 100;
  margin: 1px 1px;
  cursor: pointer;
}

.button:hover {
  border:1px solid rgba(129, 24, 92, 1.0);
  border-radius:4px;
  background-color:grey;
  color: rgba(129, 24, 92, 0.2);
  padding:3px 6px;
  text-align: center;
  font-size: 18px;
  font-weight: 100;
  margin: 1px 1px;
  cursor: pointer;
}

blockquote { 
  font-family: Open Sans; 
  font-size: 21px; font-style: normal; 
  font-variant: normal; 
  font-weight: 400; line-height: 30px; 
} 

pre { font-family: 
  Open Sans; font-size: 13px; 
  font-style: normal; 
  font-variant: normal; 
  font-weight: 400; 
  line-height: 18.5667px; 
}

.center-image {
  display: block;
  text-align: center;
}

.table-grey {
  border-collapse: collapse;
  width: 100%;
  color:#F0F8FF;
  background-color:#610A41; 
}

.table-grey table, .table-grey th {
  height: 45px;
  text-align:center;
  font-size:large;
  vertical-align:middle;
}

.table-grey table, .table-grey th {
  border:1px solid #F0F8FF;
  padding:2px;
  background-color: #8a8685; 
}

.table-border table, .table-grey td  {
  border:1px solid #F0F8FF;
  padding:5px;
  background-color:#265b5f; 
}  

.table-schema table, .table-schema th {
  height: 45px;
  text-align:center;
  font-size:large;
  vertical-align:middle;
}

.table-schema table, .table-schema td  {
  padding:5px;
}  

/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.Marquee {
  display: block;
  overflow-x: hidden;
}

.dumb img:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.05s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.triangle div {
  position: relative;
  top: 10%;
  left: 90%;
  width: 10%;
  height: 10%;
  transform: rotate(45deg);
  background: green;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

